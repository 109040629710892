import React from "react"
import { Link } from "gatsby"

import NavItem from "../NavItem"
import Svg from "../Svg"
import styles from "./index.module.scss"
import useTranslation from "../../hooks/useTranslation"

const siteURL = process.env.GATSBY_SITE_URL

const DesktopMenu = ({
  links,
  chapterLinks,
  setSolutionsIsOpen,
  setServicesIsOpen,
  setAboutUsIsOpen,
  isOpen,
}) => {
  const { t } = useTranslation("mainMenu")

  return (
    <>
      <NavItem link={links.logo} style="dropdown" />

      <NavItem link={links.superpowers} style="dropdown" />

      <NavItem style="dropdown" link={links.services} couldOpen setIsOpen={setServicesIsOpen} isOpen={isOpen.services}>
        <div className={styles["submenu-wrapper"]}>
          <div className={styles["submenu__column"]}>
            <h4>{t("services.title")}</h4>

            <h5>{t("services.subtitle")}</h5>
          </div>

          <div className={styles["submenu__column"]}>
            <Link to="/services" className={styles.linkHeader}>
              {t("services.consulting.title")}
            </Link>
            
            {chapterLinks.services.consulting.map((item, index) => (
              <Link key={`ConsMnu_${index}`} to={item.url}>
                {item.title}
              </Link>
            ))}
          </div>

          <div className={styles["submenu__column"]}>
            <Link to="/services" className={styles.linkHeader}>
              {t("solutions.training.title")}
            </Link>

            <div className={styles["submenuLinkBox"]}>
              {chapterLinks.services.training.map((item, index) => (
                <Link key={`TrainMnu_${index}`} to={item.url}>
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </NavItem>

      <NavItem style="dropdown" link={links.solutions} couldOpen setIsOpen={setSolutionsIsOpen} isOpen={isOpen.solutions}>
        <div className={styles["submenu-wrapper"]}>
          <div className={styles["submenu__column"]}>
            <h4>{t("solutions.title")}</h4>

            <h5>{t("solutions.subtitle")}</h5>
          </div>

          <div className={styles["submenu__column"]}>
            <Link to="/solutions" className={styles.linkHeader}>
              {t("solutions.solutions.title")}
            </Link>

            <div className={styles["submenuLinkBox"]}>
              {chapterLinks.solutions.solutions.map((item, index) => (
                <Link key={`SolMnu_${index}`} to={item.url}>
                  {item.title}
                </Link>
              ))}
            </div>
          </div>

          <div className={styles["submenu__column"]}>
            <h6>{t("solutions.industries.title")}</h6>

            <div className={styles["submenuLinkBox"]}>
              {chapterLinks.solutions.industries.map((item, index) => (
                <Link key={`IndMnu_${index}`} to={item.url}>
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </NavItem>

      <NavItem style="dropdown" link={links.joinUs} />

      <NavItem style="dropdown" link={links.aboutUs} couldOpen setIsOpen={setAboutUsIsOpen} isOpen={isOpen.aboutUs}>
        <div className={styles["submenu-wrapper"]}>
          <div className={styles["submenu__column"]}>
            <h4>{t("aboutUs.title")}</h4>

            <h5>{t("aboutUs.subtitle")}</h5>
          </div>

          <div className={styles["submenu__column"]}>
            <h6>{t("aboutUs.about.title")}</h6>

            {chapterLinks.aboutUs.about.map((item, index) => (
              <Link key={`AboutMnu_${index}`} to={item.url}>
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      </NavItem>
    </>
  )
}

export default DesktopMenu
