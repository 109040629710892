import HeaderLogo from "../../assets/svg/headerLogo.svg"
import Flash from "../../assets/svg/flash.svg"
import DropdownArrow from "../../assets/svg/dropdown.svg"
import ShowMore from "../../assets/svg/showMore.svg"
import ShowMoreSolid from "../../assets/svg/showMoreSolid.svg"
import HeaderLogoTablet from "../../assets/svg/headerLogoTablet.svg"
import TFI from "../../assets/svg/tfi.svg"
import GivingPeople from "../../assets/svg/GivingPeople.svg"
import GEO from "../../assets/svg/GEO.svg"
import Mobile from "../../assets/svg/mobile.svg"
import Envelope from "../../assets/svg/envelope.svg"
import GEOMobile from "../../assets/svg/GEOMobile.svg"
import MobileMobile from "../../assets/svg/mobileMobile.svg"
import EnvelopeMobile from "../../assets/svg/envelopeMobile.svg"
import TFIMobile from "../../assets/svg/tfiMobile.svg"
import GivingPeopleMobile from "../../assets/svg/givingMobile.svg"
import ContactFacebook from "../../assets/svg/ContactFacebook.svg"
import ContactGit from "../../assets/svg/ContactGit.svg"
import ContactLinkedIn from "../../assets/svg/ContactLinkedIn.svg"
import ContactTwitter from "../../assets/svg/ContactTwitter.svg"
import Soff from "../../assets/svg/soff.svg"

export default {
  HeaderLogo,
  Flash,
  DropdownArrow,
  ShowMore,
  ShowMoreSolid,
  HeaderLogoTablet,
  TFI,
  GivingPeople,
  GEO,
  Mobile,
  Envelope,
  GEOMobile,
  MobileMobile,
  EnvelopeMobile,
  TFIMobile,
  GivingPeopleMobile,
  ContactFacebook,
  ContactGit,
  ContactLinkedIn,
  ContactTwitter,
  Soff
}
