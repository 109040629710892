import {graphql, Link, useStaticQuery} from "gatsby"
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import useMedia from "../../hooks/useMedia"
import Svg from "../Svg"
import styles from "./index.module.scss"
import DesktopMenu from "../DesktopMenu"
import MobileMenu from "../MobileMenu"
import useTranslation from "../../hooks/useTranslation"
import useGetBrowserLanguage from "../../hooks/useGetBrowserLanguage";
import { remark } from "remark"
import remarkHtml from "remark-html"
import findLangIndex from "../../helpers/findLangIndex";

//FIXME: Extract to common file
const getTextFromTags = (str, tag) => {
  let result
  if (tag === "h1") {
    result = str.match(/<h1>(.*?)<\/h1>/g)?.map(val => {
      return val.replace(/<\/?h1>/g, "")
    })
  } else if (tag === "h4") {
    result = str.match(/<h4>(.*?)<\/h4>/g)?.map(val => {
      return val.replace(/<\/?h4>/g, "")
    })
  } else {
    result = str.match(/<h2>(.*?)<\/h2>/g)?.map(val => {
      return val.replace(/<\/?h2>/g, "")
    })
  }
  return result?.[0]
}

const getTrainingInfo = (remarkObj, src) => {
  const srcHtml = remarkObj.processSync(src);

  const title = getTextFromTags(srcHtml.value, "h4");
  const anchor = title.toLowerCase();

  return {
    title,
    anchor
  };
}

const getIndustryTitle = (remarkObj, src) => {
  const srcHtml = remarkObj.processSync(src);
  return getTextFromTags(srcHtml.value, "h1");
}

const Header = () => {
  const { t } = useTranslation("mainMenu")

  const language = useGetBrowserLanguage();

  const [isOpen, setIsOpen] = useState({
    services: false,
    solutions: false,
    aboutUs: false,
  })
  const [isSticky, setSticky] = useState(true)
  const [isMenuMobileOpen, setMenuMobileOpen] = useState(false)
  const ref = useRef(null)
  const headerLogo = useMedia(
    ["(min-width: 1278px)", "(min-width: 768px)", "(min-width: 320px)"],
    [<Svg.HeaderLogo />, <Svg.HeaderLogoTablet />, <Svg.HeaderLogo />],
    <Svg.HeaderLogo />
  )
  const isShowMobileMenu = useMedia(
    ["(min-width: 1278px)", "(min-width: 320px)"],
    [false, true],
    false
  )
  const links = useMemo(
    () => ({
      logo: (
        <div className={styles["navbar-nav__link"]}>
          <Link to="/">{headerLogo}</Link>
        </div>
      ),
      superpowers: (
        <div className={styles["navbar-nav__link"]}>
          <Link to="/our-superpowers">{t("superpowers.title")}</Link>
          {!isShowMobileMenu && <Svg.Flash />}
        </div>
      ),
      services: (
        <div className={styles["navbar-nav__link"]}>
          <p>{t("services.title")}</p>
          <Svg.DropdownArrow />
        </div>
      ),
      solutions: (
        <div className={styles["navbar-nav__link"]}>
          <p>{t("solutions.title")}</p>
          <Svg.DropdownArrow />
        </div>
      ),
      joinUs: (
        <div className={styles["navbar-nav__link"]}>
          <Link to="/join-us">{t("joinUs.title")}</Link>
        </div>
      ),
      aboutUs: (
        <div className={styles["navbar-nav__link"]}>
          <p>{t("aboutUs.title")}</p>
          <Svg.DropdownArrow />
        </div>
      ),
    }),
    [headerLogo]
  )

  const { servicesData, solutionsData } = useStaticQuery(graphql`
    query MnuDataQuery {
      servicesData: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(services)/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              training_blocks {
                image
                name
              }
            }
          }
        }
      }
      
      solutionsData: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(solutions.md)/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              title_description
              section_description
              technologies {
                title
                slug
              }
              industries {
                slug
                content
              }
            }
          }
        }
      }
    }
  `)

  const remarkObj = useMemo(() => remark().use(remarkHtml), [])

  const langIdServices = useMemo(
    () => { return findLangIndex(servicesData?.edges, "services/services.md", language); },
    [servicesData, language]
  );
  const langIdSolutions = useMemo(
    () => { return findLangIndex(solutionsData?.edges, "solutions/solutions.md", language); },
    [solutionsData, language]
  );

  const chapterLinks = {
    services: {
      consulting: [
        {
          title: t("services.consulting.hireUs"),
          url: "/about-us#contactUs"
        },
      ],
      training: servicesData?.edges?.[langIdServices]?.node?.frontmatter
          ?.training_blocks.map(item => {
            const { title, anchor } = getTrainingInfo(remarkObj, item.name);
            return {
              title,
              url: `/services#${anchor}`
            }
          })
    },
    solutions: {
      solutions: [
        ...solutionsData?.edges?.[langIdSolutions]?.node?.frontmatter
            ?.technologies?.map(item => ({
              title: item.title,
              url: `/solutions/${item.slug}`
            }))
      ],
      industries: solutionsData?.edges?.[langIdSolutions]?.node?.frontmatter
          ?.industries.map(item => ({
                title: getIndustryTitle(remarkObj, item.content),
                url: `/solutions/industry/${item.slug}`
              }
          ))
    },
    aboutUs: {
      about: [
        { title: t("aboutUs.about.story"), url: "/about-us#ourStory" },
        { title: t("aboutUs.about.clients"), url: "/about-us#ourClients" },
        { title: t("aboutUs.about.contactUs"), url: "/about-us#contactUs" },
      ],
    },
  }

  useEffect(() => {
    if (isMenuMobileOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [isMenuMobileOpen])
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0)
    }
  }

  const setMobileNavIsOpen = type => {
    switch (type) {
      case "services":
        setIsOpen({ ...isOpen, services: !isOpen.services })
        break
      case "solutions":
        setIsOpen({ ...isOpen, solutions: !isOpen.solutions })
        break
      case "aboutUs":
        setIsOpen({ ...isOpen, aboutUs: !isOpen.aboutUs })
        break
      default:
        return isOpen
    }
  }

  const setServicesIsOpen = useCallback(() => {
    setIsOpen({ services: !isOpen.services, solutions: false, aboutUs: false })
  }, [isOpen])
  const setSolutionsIsOpen = useCallback(() => {
    setIsOpen({ services: false, solutions: !isOpen.solutions, aboutUs: false })
  }, [isOpen])
  const setAboutUsIsOpen = useCallback(() => {
    setIsOpen({ services: false, solutions: false, aboutUs: !isOpen.aboutUs })
  }, [isOpen])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  const handleMenuMobileOpen = useCallback(() => {
    setMenuMobileOpen(!isMenuMobileOpen)
  }, [isMenuMobileOpen])

  const isToggledNav = useMemo(() => {
    if (isOpen.aboutUs === true) {
      return true
    } else if (isOpen.services === true) {
      return true
    } else if (isOpen.solutions === true) {
      return true
    } else {
      return false
    }
  }, [isOpen])

  return (
    <Fragment>
      <div
        className={[
          styles["sticky-wrapper"],
          isSticky ? styles["sticky"] : "",
        ].join(" ")}
        ref={ref}
      >
        <header className={styles["header"]}>
          <nav
            className={[
              styles["navbar"],
              isToggledNav ? styles["navbar__toggled"] : "",
            ].join(" ")}
          >
            {!isShowMobileMenu && (
              <ul className={styles["navbar-nav"]}>
                <DesktopMenu
                  links={links}
                  chapterLinks={chapterLinks}
                  setServicesIsOpen={setServicesIsOpen}
                  setSolutionsIsOpen={setSolutionsIsOpen}
                  setAboutUsIsOpen={setAboutUsIsOpen}
                  isOpen={isOpen}
                />
              </ul>
            )}
            {!isShowMobileMenu && (
              <Link to="/about-us#contactUs" className={styles["contact-link"]}>
                {t("contactUs")}
              </Link>
            )}
            {isShowMobileMenu && (
              <div className={styles["navbar-nav__wrapper--mobile"]}>
                <div className={styles["navbar-nav__link"]}>
                  <Link to="/">{headerLogo}</Link>
                </div>
                <div
                  className={[
                    styles["hamburger"],
                    isMenuMobileOpen ? styles["open"] : "",
                  ].join(" ")}
                  onClick={handleMenuMobileOpen}
                >
                  <span />
                  <span />
                  <span />
                </div>
                <MobileMenu
                  links={links}
                  chapterLinks={chapterLinks}
                  setMobileNavIsOpen={setMobileNavIsOpen}
                  isOpen={isOpen}
                  isMenuMobileOpen={isMenuMobileOpen}
                  setMenuMobileOpen={handleMenuMobileOpen}
                />
              </div>
            )}
          </nav>
        </header>
      </div>
    </Fragment>
  )
}

export default Header
