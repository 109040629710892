const res = {}
export default function recurse(obj, current) {
  for (let key in obj) {
    let value = obj[key]
    let newKey = current ? current + "." + key : key
    if (value && typeof value === "object") {
      recurse(value, newKey)
    } else {
      res[newKey] = value
    }
  }
  return res
}
