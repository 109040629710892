import React from "react"
import { Link } from "gatsby"

import NavItem from "../NavItem"
import styles from "./index.module.scss"
import useTranslation from "../../hooks/useTranslation"

const MobileMenu = ({
  links,
  chapterLinks,
  isOpen,
  isMenuMobileOpen,
  setMobileNavIsOpen,
  setMenuMobileOpen,
}) => {
  const { t } = useTranslation("mainMenu")

  return (
    <div
      className={[
        styles["menu-mobile__wrapper"],
        isMenuMobileOpen ? styles["open"] : "",
      ].join(" ")}
    >
      <ul className={styles["menu-mobile"]}>
        <NavItem style="dropdown-mobile" link={links.superpowers} />
        <NavItem
          style="dropdown-mobile"
          link={links.services}
          couldOpen
          setIsOpen={() => setMobileNavIsOpen("services")}
          isOpen={isOpen.services}
        >
          <div className={styles["submenu__column"]}>
            <h6>{t("services.consulting.title")} </h6>
            {chapterLinks.services.consulting.map((item, index) => (
              <Link
                key={`ConsMnu_${index}`}
                to={item.url}
                onClick={setMenuMobileOpen}
              >
                {item.title}
              </Link>
            ))}
          </div>
          <div className={styles["submenu__column"]}>
            <h6>Training</h6>
            {chapterLinks.services.training.map((item, index) => (
              <Link
                key={`TrainMnu_${index}`}
                to={item.url}
                onClick={setMenuMobileOpen}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </NavItem>
        <NavItem
          style="dropdown-mobile"
          link={links.solutions}
          couldOpen
          setIsOpen={() => setMobileNavIsOpen("solutions")}
          isOpen={isOpen.solutions}
        >
          <div className={styles["submenu__column"]}>
            <Link to="/solutions" className={styles.linkHeader}>
              {t("solutions.solutions.title")}
            </Link>
            {chapterLinks.solutions.solutions.map((item, index) => (
              <Link
                key={`SolMnu_${index}`}
                to={item.url}
                onClick={setMenuMobileOpen}
              >
                {item.title}
              </Link>
            ))}
          </div>
          <div className={styles["submenu__column"]}>
            <h6>{t("solutions.industries.title")}</h6>
            {chapterLinks.solutions.industries.map((item, index) => (
              <Link
                key={`IndMnu_${index}`}
                to={item.url}
                onClick={setMenuMobileOpen}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </NavItem>
        <NavItem style="dropdown-mobile" link={links.joinUs} />
        <NavItem
          style="dropdown-mobile"
          link={links.aboutUs}
          couldOpen
          setIsOpen={() => setMobileNavIsOpen("aboutUs")}
          isOpen={isOpen.aboutUs}
        >
          <div className={styles["submenu__column"]} />
          <div className={styles["submenu__column"]}>
            <Link to="/about-us" className={styles.linkHeader}>
              {t("aboutUs.about.title")}
            </Link>
            {chapterLinks.aboutUs.about.map((item, index) => (
              <Link
                key={`AboutMnu_${index}`}
                to={item.url}
                onClick={setMenuMobileOpen}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </NavItem>

        <div className={styles["btn-wrapper"]}>
          <Link to="/about-us#contactUs" className={styles["contact-link"]}>
            {t("contactUs")}
          </Link>
        </div>
      </ul>
    </div>
  )
}

export default MobileMenu
