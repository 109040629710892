import React, { createContext, useContext, useState } from "react"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import { Helmet } from "react-helmet"
import AnchorGuard from "../AnchorGuard"
import Header from "../Header"
import Footer from "../Footer"
import HubspotConnect from "../HubspotConnect"
import "react-toastify/dist/ReactToastify.css"
import styles from "./index.module.scss"
import recurse from "../../helpers/convertLang"
import { buildTextdomain } from "react-gettext"
// json data
import mainPage from "../../../static/locales/en/MainPage.json"
import footer from "../../../static/locales/en/Footer.json"
import joinUs from "../../../static/locales/en/JoinUsPage.json"
import mainMenu from "../../../static/locales/en/MainMenu.json"
import page404 from "../../../static/locales/en/Page404.json"
import qrCode from "../../../static/locales/en/QRCode.json"

const initialData = {
  mainPage: buildTextdomain(
    recurse({
      ...mainPage,
    })
  ),
  footer: buildTextdomain(
    recurse({
      ...footer,
    })
  ),
  joinUs: buildTextdomain(
    recurse({
      ...joinUs,
    })
  ),
  mainMenu: buildTextdomain(
    recurse({
      ...mainMenu,
    })
  ),
  page404: buildTextdomain(
    recurse({
      ...page404,
    })
  ),
  qrCode: buildTextdomain(
    recurse({
      ...qrCode,
    })
  )
}

const TranslationContext = createContext({
  siteData: initialData,
})

const Layout = ({ children }) => {
  return (
    <TranslationContext.Provider value={{ siteData: initialData }}>
      <div className={styles["c-wrapper"]}>
        <AnchorGuard />
        <Helmet>
          <title>Dynamist</title>

          <meta property="og:url" content="https://dynamist.se/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Dynamist" />
          <meta
            property="og:description"
            content="We work within DevOps, with agile working methods and modern tools. We develop tools and integrations that secure and streamline your system infrastructure. Something that leads to predictable deliveries and reduced lead times."
          />
          <meta
            property="og:image"
            content="https://dynamist.se/theme/images/slide2.jpg"
          />
          <meta property="og:image:alt" content="Dynamist" />
          <meta property="og:locale" content="en_US" />
          <meta property="fb:app_id" content="1723267604568985" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@dynamister" />
          <meta name="twitter:creator" content="@dynamister" />

          <link rel="canonical" href="https://dynamist.se/en/" />
        </Helmet>
        <ToastContainer theme="dark" />
        <Header />
        <main className={styles["c-content"]}>{children}</main>
        <Footer />
        <HubspotConnect />
      </div>
    </TranslationContext.Provider>
  )
}

Layout.defaultProps = {
  children: "",
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export { TranslationContext }
export default Layout
