import React, { useEffect } from "react"

const AnchorGuard = () => {
  const isBrowser = typeof window !== "undefined"
  const currUrl = isBrowser ? window.location.href : null

  useEffect(() => {
    if (!currUrl)
      return;

    const urlObj = new URL(currUrl)
    if (urlObj.hash) {
      const focusEl = document.getElementById(urlObj.hash.substring(1))
      if (focusEl) {
        focusEl.scrollIntoView()
      }
    }
  }, [currUrl])

  return <></>
}

export default AnchorGuard
