const extractLanguage = (path, fileName) => {
  const fixedFileName = fileName.replace("/", "\\/");
  const langRegExp = new RegExp(`.*/(?<lang>.*)/${fixedFileName}`);
  const langRes = path.match(langRegExp);

  return langRes ? langRes.groups.lang : undefined;
};

const findLangIndex = (edges, fileName, langName) => {
  if (edges) {
    const edgeCount = edges.length;
    for (let edgeIdx=0; edgeIdx<edgeCount; edgeIdx++) {
      const edgeLang = extractLanguage(edges[edgeIdx]?.node.fileAbsolutePath, fileName);
      if (edgeLang === langName) {
        return edgeIdx;
      }
    }
  }

  return 0;
}

export default findLangIndex;
