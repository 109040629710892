import React from "react"
import styles from "./index.module.scss"

const DropdownMenu = ({ children, isOpen, style }) => {
  return (
    <div
      className={[
        styles[`${style}`],
        isOpen ? styles["dropdown-open"] : "",
      ].join(" ")}
    >
      {children}
    </div>
  )
}

export default DropdownMenu
