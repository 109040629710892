import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

const HubspotConnect = () => {
    const { site } = useStaticQuery(graphql`
        query HsConnectMetadata {
            site {
                siteMetadata {
                    hubspotId
                }
            }
        }
    `);

    useEffect(() => {
        const node = document.createElement("script");
        node.id = "hs-script-loader";
        node.type = "text/javascript";
        node.src = `//js-eu1.hs-scripts.com/${site.siteMetadata.hubspotId}.js`;
        node.async = true;
        node.defer = true;

        document.body.appendChild(node);

        return () => {
            document.body.removeChild(node);
        };
    }, [site])

    return <></>;
};

export default HubspotConnect;