import React from "react"
import DropdownMenu from "../DropdownMenu"
import styles from "./index.module.scss"

const NavItem = ({ children, link, couldOpen, setIsOpen, isOpen, style }) => {
  const handleDropdown = () => {
    if (couldOpen) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <li
      className={[
        styles["nav-item"],
        isOpen ? styles["nav-item--active"] : "",
      ].join(" ")}
      onClick={handleDropdown}
    >
      {link}
      {couldOpen && (
        <DropdownMenu style={style} isOpen={isOpen}>
          {children}
        </DropdownMenu>
      )}
    </li>
  )
}

export default NavItem
