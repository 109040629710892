import { useContext } from "react"
import { TranslationContext } from "../components/Layout"

const useTranslation = name => {
  const { siteData } = useContext(TranslationContext)
  const { gettext } = siteData[name]
  return {
    t: val => {
      return gettext(val)
    },
  }
}

export default useTranslation
